'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.assets
 # @description

###
angular
  .module 'mundoAdmin.assets', [
    'ui.router'
  ]
